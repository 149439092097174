
<template>
    <div class="c-app flex-row align-items-center" style="background:rgba(108, 122, 137, 0.35);background-image:url('/static/Avante_BG.png')">
            <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
        <CContainer >
            <CRow class="justify-content-center" >
                <CCol md="4">
                    <CCard class="p-5 organization-panel" style="border-radius:20px;box-shadow: 0px 5px 80px 0px;">
                        <CForm>
                            <center>
                              <img :src="'/static/Avante_Logo_PNG.png'" class="company-logo">
                            </center>
                            <br>
                            <center><h3>Type Your Organization</h3></center>
                            <hr>
                           <div>
                            <form @submit.prevent="selectOrg">
                                <input ref="input" class="form-control" style="text-transform:uppercase" autofocus v-model="org_value"/>   
                            </form>
                           </div>
                        </CForm>
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>
<style>
.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: #289495;
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}
</style>
<script>
export default {
  name: "Organization",
  data() { 
    return {
        // organization_list: [],
        org_value: "",
        organization_exists: false
    } 
  },
  created() {
    // this.getOrganization();

    document.title = "Avante"
  },
  methods: {
        selectOrg() {
            axios.get(process.env.VUE_APP_BACKEND_URL+'/organization/'+this.org_value, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$router.push({ path: '/'+this.org_value.toLowerCase()+'/login' })
                } else {
                    this.organization_exists = false;
                }
            })
        }
        // getOrganization: function () {
        //     this.$Progress.start()  
        //     axios.get(process.env.VUE_APP_BACKEND_URL+'/organization', {validateStatus: () => true})
        //     .then(response => {
        //         if(response.status == 200) {
        //             for(let i = 0; i < response.data.data.length; i++) {
        //                 this.organization_list.push(response.data.data[i].org_name)
        //                 this.$Progress.finish()
        //             }
        //         }
        //     })
        // },
  },
  // watch: {
  //   org_value: function (value) {
  //       this.$router.push({ path: '/'+value.toLowerCase()+'/login' })
  //   }
  // },
};
</script>